@keyframes roller {
  0% {
    top: 0;
  }
  50% {
    top: -100%;
  }
  100% {
    top: -200%;
  }
}
