.trustTitle {
  font-size: clamp(1.5rem, 5.208vw, 2.5rem);
  font-weight: 600;
  color: #4545C9;
}

.trustDescription {
  font-weight: 600;
  font-size: clamp(0.75rem, 2.604vw, 1.25rem);
  line-height: 150%;
}

.trustDescription + .trustDescription {
  margin-top: 2.604vw;
}
