.benefitTitle {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 2.604vw;
  font-weight: 600;
  font-size: clamp(1.4rem, 2.813vw, 3.375rem);
  color: #4545C9;
}

.benefitDescription {
  position: relative;
  display: flex;
  align-items: start;
  font-weight: 600;
  font-size: clamp(0.8rem, 1.25vw, 1.5rem);
  padding: 1.302vw 2.604vw;
  line-height: 150%;
}

.benefitDescription::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10%;
  right: 10%;
  border-top: 1px solid rgba(204, 204, 236, 1);
}

.benefitFigure {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.302vw;
}
