:root {
  --header-height: 80px;
  --content-border-color: #ccccec;
  --grid-color: rgba(204, 204, 236, 0.3);
  --grid-color-mobile: rgba(204, 204, 236, 0.2);
  --grid-width: 100vw;
  --grid-num: 12;
  --grid-column-width: calc(var(--grid-width) / var(--grid-num));

  --grid-unit-1: var(--grid-column-width);
  --grid-unit-2: calc(var(--grid-column-width) * 2);
  --grid-unit-3: calc(var(--grid-column-width) * 3);
  --grid-unit-4: calc(var(--grid-column-width) * 4);
  --grid-unit-5: calc(var(--grid-column-width) * 5);
  --grid-unit-6: calc(var(--grid-column-width) * 6);
  --grid-unit-7: calc(var(--grid-column-width) * 7);
  --grid-unit-8: calc(var(--grid-column-width) * 8);
  --grid-unit-9: calc(var(--grid-column-width) * 9);
  --grid-unit-10: calc(var(--grid-column-width) * 10);
  --grid-unit-11: calc(var(--grid-column-width) * 11);
  --grid-unit-12: calc(var(--grid-column-width) * 12);
  --grid-unit-13: calc(var(--grid-column-width) * 13);
  --grid-unit-14: calc(var(--grid-column-width) * 14);
  --grid-unit-15: calc(var(--grid-column-width) * 15);
  --grid-unit-16: calc(var(--grid-column-width) * 16);
  --grid-unit-17: calc(var(--grid-column-width) * 17);
  --grid-unit-18: calc(var(--grid-column-width) * 18);
  --grid-unit-19: calc(var(--grid-column-width) * 19);
  --grid-unit-20: calc(var(--grid-column-width) * 20);
  --grid-unit-21: calc(var(--grid-column-width) * 21);
  --grid-unit-22: calc(var(--grid-column-width) * 22);
  --grid-unit-23: calc(var(--grid-column-width) * 23);
  --grid-unit-24: calc(var(--grid-column-width) * 24);

  --section-title-font-size: clamp(2rem, 5vw, 6rem);
}

@media only screen and (min-width: 768px) {
  :root {
    --grid-num: 24;
  }
}

.bgGridY {
  background-image: linear-gradient(to bottom, var(--grid-color-mobile) 1px, transparent 1px);
  background-size: calc(var(--grid-width) / var(--grid-num)) calc(var(--grid-width) / var(--grid-num));
  background-repeat: repeat;
}

@media only screen and (min-width: 768px) {
  .bgGridY {
    background-image: linear-gradient(to bottom, var(--grid-color) 1px, transparent 1px);
  }
}

.bgGridX {
  background-image: linear-gradient(to right, var(--grid-color-mobile) 1px, transparent 1px);
  background-size: calc((var(--grid-width) - 1px) / var(--grid-num)) auto;
  background-repeat: repeat;
}

@media only screen and (min-width: 768px) {
  .bgGridX {
    background-image: linear-gradient(to right, var(--grid-color) 1px, transparent 1px);
  }
}

.titleParagraph {
  font-weight: 700;
  font-size: clamp(1.25rem, 4.167vw, 2rem);
  line-height: 150%;
}

.titleParagraph + .descriptionParagraph {
  margin-top: 2.604vw;
}

@media only screen and (min-width: 768px) {
  .titleParagraph {
    font-size: clamp(1.2rem, 2.083vw, 2.5rem);
  }

  .titleParagraph + .descriptionParagraph {
    margin-top: 1.042vw;
  }
}

.descriptionParagraph {
  font-weight: 600;
  font-size: clamp(0.75rem, 2.604vw, 1.25rem);
  line-height: 150%;
}

.descriptionParagraph + .descriptionParagraph {
  margin-top: 0.8vw;
}


@media only screen and (min-width: 768px) {
  .descriptionParagraph {
    font-size: clamp(0.9rem, 1.25vw, 1.5rem);
  }

  .descriptionParagraph + .descriptionParagraph {
    margin-top: 0.8vw;
  }
}

.descriptionWithoutTitleParagraph {
  font-weight: 600;
  font-size: clamp(1.1rem, 2.604vw, 1.25rem);
  line-height: 150%;
}

.descriptionWithoutTitleParagraph + .descriptionWithoutTitleParagraph {
  margin-top: 2.604vw;
}

@media only screen and (min-width: 768px) {
  .descriptionWithoutTitleParagraph {
    font-size: clamp(0.9rem, 1.25vw, 1.5rem);
    line-height: 150%;
  }

  .descriptionWithoutTitleParagraph + .descriptionWithoutTitleParagraph {
    margin-top: 1.042vw;
  }
}
